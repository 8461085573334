import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import {
  getPaymentToken,
  redirectToPaymentPage,
  completeDeposit,
} from "../../utils/paymentHelper";
import {
  isValidEmail,
  isValidName,
  isPayOutValidPhoneNumber,
} from "../../utils/helpers";
import { formatPhone } from "../../utils/helpers";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import PayOutService from "../../services/payout.service";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
export default function PayOutForm({
  loggedInUser,
  onComplete,
  onPaymentError,
  onPaymentComplete,
  ipAddress,
}) {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    amount: "",
    phoneNumber: "",
  });
  let [errors, setErrors] = useState({
    firstName: 0,
    lastName: 0,
    email: 0,
    amount: 0,
    phoneNumber: 0,
  });
  const inputs = [
    {
      id: 1,
      name: "firstName",
      type: "firstName",
      placeholder: "firstName",
      errorMessage: "firstName is required",
      label: "First Name",
      required: true,
    },
    {
      id: 2,
      name: "lastName",
      type: "lastName",
      placeholder: "lastName",
      errorMessage: "lastName is required",
      label: "Last Name",
      required: true,
    },
    {
      id: 3,
      name: "phoneNumber",
      type: "phoneNumber",
      placeholder: "phoneNumber",
      errorMessage: "phone is required",
      label: "Phone",
      required: true,
    },
    {
      id: 4,
      name: "amount",
      type: "amount",
      placeholder: "amount",
      errorMessage: "amount is required",
      label: "Amount",
      required: true,
    },
    {
      id: 5,
      name: "email",
      type: "email",
      placeholder: "email",
      errorMessage: "email is required",
      label: "Email",
      required: true,
    },
  ];
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [formattedPhone, setFormattedPhone] = useState("");
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleValidation = (e) => {
    if (e.target.name == "firstName" || e.target.name == "amount")
      !isValidName(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    if (e.target.name == "lastName")
      !isValidName(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    if (e.target.name == "email") {
      !isValidEmail(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    }
    if (e.target.name == "phoneNumber")
      !isPayOutValidPhoneNumber(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
  };
  const handleSubmit = () => {
    if (
      Object.values(values).some((v) => v == "") ||
      !Object.values(errors).every((v) => v === 0)
    ) {
      setAlert("NotComplete");
    } else {
      setAlert("");
      setLoading(true);
      createPayOut();
    }
  };
  const createPayOut = () => {
    const payOutObj = {
      userId: loggedInUser.id,
      name: values.firstName + " " + values.lastName,
      phoneNumber: values.phoneNumber,
      amount: values.amount,
      payOutStatus: "Not Completed",
      paymentType: "ETO",
      email: values.email,
    };
    PayOutService.addPayOut(payOutObj)
      .then((res) => {
        //
        getPaymentToken(
          new URLSearchParams({
            userId: loggedInUser.id,
            transactionId: res.ticketNo,
            name: values.firstName + " " + values.lastName,
            email: values.email,
            site: process.env.REACT_APP_SITE,
            userIp: ipAddress || "70.67.168.255",
            mobile: values.phoneNumber,
            currency: "CAD",
            language: "en",
            amount: values.amount,
            type: "ETO",
            sandbox: process.env.REACT_APP_SANDBOX,
            hosted: false,
          })
        )
          .then((payResponse) => {
            if (!payResponse.errorMsg) {
              redirectToPaymentPage(
                new URLSearchParams({
                  token: payResponse.data,
                }),
                res.ticketNo
              )
                .then((response) => {
                  if (response.ok && response.status == 200) {
                    completeDeposit(payResponse.data, res.ticketNo)
                      .then((depositResponse) => {
                        onPaymentComplete();
                      })
                      .catch((error) => {
                        console.error("Error:", error.message);
                        onPaymentError();
                      });
                  } else {
                    onPaymentError();
                  }
                })
                .catch((error) => {
                  console.error("Error:", error.message);
                  onPaymentError();
                });
            } else onPaymentError();
          })
          .catch((error) => {
            console.error("Error:", error.message);
            onPaymentError();
          });
      })
      .catch((err) => {
        setAlert("fail");
      });
  };
  const onPhoneChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setFormattedPhone(formatPhone(e.target.value, ""));
  };
  return (
    <>
      <Card className="mx-auto w-full max-w-[24rem]">
        {!loading && (
          <>
            <CardBody className="flex flex-col gap-6">
              {alert && alert == "NotComplete" && (
                <AlertCustomStyles
                  status={alert}
                  message={"Complete form and try again!"}
                />
              )}
              <Typography
                variant="h4"
                color="blue-gray"
                className="font-nunito"
              >
                Create a pay out
              </Typography>
              <Typography
                className="mb-3 font-normal"
                variant="paragraph"
                color="gray"
              >
                Add the pay out details.
              </Typography>
              {inputs.map((input) => (
                <>
                  {input.name != "phoneNumber" && (
                    <Input
                      label={input.label}
                      name={input.name}
                      required
                      onChange={onChange}
                      onBlur={handleValidation}
                      error={errors[input.name]}
                      value={values[input.name]}
                    />
                  )}
                  {input.name == "phoneNumber" && (
                    <Input
                      label={input.label}
                      name={input.name}
                      required
                      onChange={onPhoneChange}
                      onBlur={handleValidation}
                      error={errors[input.name]}
                      value={formattedPhone}
                      maxLength={14}
                    />
                  )}
                </>
              ))}
            </CardBody>
            <CardFooter className="pt-0 flex gap-3 m-auto">
              <Button
                variant="button"
                onClick={handleSubmit}
                className="bg-[#882699] hover:bg-[#cf3be9] normal-case md:text-[15px] text-[12px]"
                size="sm"
              >
                Create
              </Button>
              <Button
                variant="outlined"
                color="red"
                onClick={() => onComplete({ type: "Address", result: null })}
                size="sm"
                className="normal-case md:text-[15px] text-[12px]"
              >
                <span>Cancel</span>
              </Button>
            </CardFooter>
          </>
        )}
        {loading && (
          <div className="p-20">
            <SpinnerWithInfo message="Saving changes..." />
          </div>
        )}
      </Card>
    </>
  );
}
