import React, { useState } from "react";
import {
  Stepper,
  Step,
  Button,
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import CheckoutForm from "./CheckoutForm";
import RecipientForm from "./RecipientForm";
import TransferService from "../../services/transfer.service";
import { StickyNavbar } from "../layout/StickyNavBar";
import AmountForm from "./AmountForm";
import InteracPaymentPage from "./InteracPaymentPage";
import Summary from "./Summary";
import { paymentStatuses, transferStatuses } from "../../utils/constants";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
const Send = ({ handleCancel, loggedInUser, ipAddress }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [amtData, setAmtData] = useState([]);
  const [recipientData, setRecipientData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [saving, setSaving] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [savingComplete, setSavingComplete] = useState(false);
  const [alert, setAlert] = useState("");
  const [newTransfer, setNewTransfer] = useState({});

  const handlePrev = (res) => {
    if (res == "recipient") {
      setRecipientData([]);
      setAmtData([]);
    }
    !isFirstStep && setActiveStep((cur) => cur - 1);
  };
  const handleNext = (data) => {
    setAlert("");
    setAmtData(data);
    !isLastStep && setActiveStep((cur) => cur + 1);
  };
  const handleNextRecipient = (data) => {
    setRecipientData(data);
    !isLastStep && setActiveStep((cur) => cur + 1);
  };
  const handleSubmit = (cData) => {
    setCardData(cData);
    //setSaving(true);
    const updateTransferObj = {
      paymentType: cData.paymentType,
      paymentStatus: paymentStatuses.NOT_COMPLETED,
      ticketNo: newTransfer.ticketNo,
      id: newTransfer.id,
    };
    TransferService.editTransfer(updateTransferObj)
      .then((res) => {
        setOpenPaymentDialog(true);
      })
      .catch((err) => {
        setAlert("fail");
        onHandleSendMore();
      });
  };
  const onHandleSendMore = () => {
    setAmtData([]);
    setCardData([]);
    setActiveStep(0);
    //setSaving(false);
  };
  const onHandleTrackTransfer = () => {
    // setSaving(false);
    setAmtData([]);
    setCardData([]);
    setActiveStep(0);
    // setSaving(false);
    handleCancel();
  };
  const createTransfer = () => {
    console.log("going to create..", amtData);
    const transferObj = {
      userId: loggedInUser.id,
      rName: recipientData.rName,
      rCountry: amtData.rCountry,
      rCity: recipientData.rCity,
      rPhoneNumber: recipientData.rPhoneNumber,
      rAmount: amtData.rAmount,
      sAmount: amtData.sAmount,
      transferStatus: transferStatuses.INITIATED,
      rCurrency: amtData.rCurrency,
      sCurrency: "CAD",
      totalPay: amtData.totalPay,
      countryCode: recipientData.countryCallingCode,
      fee: amtData.fee,
      rate: amtData.rate,
    };
    TransferService.addTransfer(transferObj)
      .then((res) => {
        setNewTransfer(res);
        !isLastStep && setActiveStep((cur) => cur + 1);
      })
      .catch((err) => {
        setAlert("fail");
        onHandleSendMore();
      });
  };
  const onPaymentError = () => {
    setAlert("fail");
    setOpenPaymentDialog(false);
  };
  const onSummaryNext = () => {
    createTransfer();
  };
  return (
    <div>
      <div className="sticky top-0 z-50 mt-2 flex md:hidden">
        <StickyNavbar pageType={"mobileView"} />
      </div>
      <div class="mx-auto max-w-screen-md max-h md:py-12 mt-4 md:mt-0">
        <div class="relative flex flex-col text-gray-700 bg-transparent md:shadow-none rounded-xl bg-clip-border m-auto">
          <div className="w-full md:py-4 md:px-4">
            <Stepper
              activeStep={activeStep}
              isLastStep={(value) => setIsLastStep(value)}
              isFirstStep={(value) => setIsFirstStep(value)}
              className=" hidden md:flex"
            >
              <Step className="h-4 w-4">
                <div className="absolute -bottom-[4.5rem] w-max text-center">
                  <Typography
                    variant="h6"
                    color={activeStep === 0 ? "blue-gray" : "gray"}
                    className="font-nunito"
                  >
                    Amount
                  </Typography>
                </div>
              </Step>
              <Step className="h-4 w-4">
                <div className="absolute -bottom-[4.5rem] w-max text-center">
                  <Typography
                    variant="h6"
                    color={activeStep === 1 ? "blue-gray" : "gray"}
                    className="font-nunito"
                  >
                    Recipient Details
                  </Typography>
                </div>
              </Step>
              <Step className="h-4 w-4">
                <div className="absolute -bottom-[4.5rem] w-max text-center">
                  <Typography
                    variant="h6"
                    color={activeStep === 2 ? "blue-gray" : "gray"}
                    className="font-nunito"
                  >
                    Summary
                  </Typography>
                </div>
              </Step>
              <Step className="h-4 w-4">
                <div className="absolute -bottom-[4.5rem] w-max text-center">
                  <Typography
                    variant="h6"
                    color={activeStep === 3 ? "blue-gray" : "gray"}
                    className="font-nunito"
                  >
                    Pay and Submit
                  </Typography>
                </div>
              </Step>
            </Stepper>

            <div className="md:mt-24 mt-2">
              {alert && (
                <div className="max-w-[34rem] m-auto  md:mt-2 mt-2">
                  <AlertCustomStyles
                    status={alert}
                    message={"Something went wrong, Please try again!"}
                  />
                </div>
              )}
              {activeStep == 0 && (
                <div class="">
                  <AmountForm
                    handleNext={(amtData) => handleNext(amtData)}
                    amtData={amtData}
                  />
                </div>
              )}
              {activeStep == 1 && (
                <div class="">
                  <RecipientForm
                    handlePrev={(step) => handlePrev(step)}
                    handleNextRecipient={handleNextRecipient}
                    recipientData={recipientData}
                    selectedCountry={amtData.rCountry}
                  />
                </div>
              )}
              {activeStep == 2 && (
                <div class="md:mt-24 mt-2">
                  <Summary
                    recipientData={recipientData}
                    amtData={amtData}
                    onSummaryNext={onSummaryNext}
                    handlePrev={handlePrev}
                  />
                </div>
              )}
              {activeStep == 3 && (
                <div class="">
                  <CheckoutForm
                    handleSubmit={handleSubmit}
                    handlePrev={handlePrev}
                    cardData={cardData}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        size="xs"
        open={openPaymentDialog}
        className="bg-transparent shadow-none"
        dismiss={{
          escapeKey: false,
          referencePress: false,
          outsidePress: false,
          ancestorScroll: false,
          bubbles: false,
        }}
      >
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardBody className="flex flex-col gap-6">
            <InteracPaymentPage
              transferData={newTransfer}
              loggedInUser={loggedInUser}
              onPaymentError={onPaymentError}
              ipAddress={ipAddress}
            />
            {/* {!savingComplete && (
                <SpinnerWithInfo message="Sending transfer..." />
              )}
              {savingComplete && <>Transfer created successfully!</>} */}
          </CardBody>
          {/*  {savingComplete && (
              <DialogFooter>
                <Button
                  variant="outlined"
                  color="blue"
                  onClick={onHandleTrackTransfer}
                  className="mr-1"
                  size="sm"
                >
                  <span>Track transfer</span>
                </Button>
                <Button
                  variant="gradient"
                  color="purple"
                  onClick={onHandleSendMore}
                  size="sm"
                >
                  <span>Send more</span>
                </Button>
              </DialogFooter>
            )} */}
        </Card>
      </Dialog>
    </div>
  );
};
export default Send;
