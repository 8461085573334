//import axios from '../utils/axios.utils';

import authHeader from "./auth-header";
import axios from "axios";
import axiosClient from "./axios-interceptors";
class PayOutService {
  async addPayOut(PayOutData) {
    try {
      const response = await axiosClient.post("/addPayOut", PayOutData);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error adding transfer:", error);
      throw new Error("Failed to add transfer. Please try again later.");
    }
  }
  async editPayOut(PayOutData) {
    try {
      const response = await axiosClient.post("/editPayOut", PayOutData);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error adding transfer:", error);
      throw new Error("Failed to add transfer. Please try again later.");
    }
  }
  async deletePayOut(data) {
    try {
      const response = await axiosClient.post("/deletePayOut", data);
      return response.data;
    } catch (error) {
      // Handle the error, possibly transform it before throwing
      console.error("Error deleting  payouts:", error);
      throw new Error("Failed to delete payout. Please try again later.");
    }
  }

  async getAllPayOuts(payOutData) {
    try {
      const response = await axiosClient.post("/getAllPayOuts", payOutData);
      return response.data;
    } catch (error) {
      // Log and handle specific error
      console.error("Error fetching all pay outs:", error);
      throw new Error("Failed to fetch all pay outs. Please try again later.");
    }
  }
}

export default new PayOutService();
