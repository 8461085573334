import React, { useEffect, useState, useRef } from "react";
import { roles, paymentStatuses } from "../../utils/constants";
import { formatPhone } from "../../utils/helpers";
import PayOutForm from "../editor-forms/PayOutForm";
import { SpinnerWithInfo } from "../layout/SpinnerWithInfo";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import {
  getPaymentToken,
  redirectToPaymentPage,
  cancelDeposit,
} from "../../utils/paymentHelper";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  Avatar,
  Dialog,
  IconButton,
} from "@material-tailwind/react";
import moment from "moment-timezone";
import { PlusIcon } from "@heroicons/react/24/solid";
import PayoutService from "../../services/payout.service";
const TABLE_HEAD = [
  "Payout ID",
  "Status",
  "Name",
  "Amount",
  "Date",
  "Phone",
  "Email",
  "Action",
];

export default function PayOut({
  loggedInUser,
  startSending,
  selectedItem,
  ipAddress,
}) {
  const [payOutData, setPayOutData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openD, setOpenD] = useState(false);
  const [alert, setAlert] = useState("");

  const itemsPerPage = 8;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = payOutData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(payOutData.length / itemsPerPage);
  const [active, setActive] = React.useState(1);
  const getItemProps = (index) => ({
    variant: active === index ? "filled" : "text",
    color: "gray",
    onClick: () => pageNumberClick(index),
  });
  const pageNumberClick = (index) => {
    const newOffset = index * itemsPerPage - itemsPerPage;
    setItemOffset(newOffset);
    setActive(index);
  };

  const next = () => {
    if (active === pageCount) return;
    const newOffset = (active + 1) * itemsPerPage - itemsPerPage;
    setItemOffset(newOffset);
    setActive(active + 1);
  };

  const prev = () => {
    if (active === 1) return;
    const newOffset = (active - 1) * itemsPerPage - itemsPerPage;
    setItemOffset(newOffset);
    setActive(active - 1);
  };
  const isAdminOrTesting = (role) => {
    return role == roles.TESTING || role == roles.ADMIN;
  };

  useEffect(() => {
    setIsLoading(true);
    PayoutService.getAllPayOuts({ userId: loggedInUser.id })
      .then(async (res) => {
        setPayOutData(res);
        setIsLoading(false);
        setTimeout(() => {
          setAlert("");
        }, 3000);
      })
      .catch((err) => {});
  }, [selectedItem, alert]);
  const onPaymentError = () => {
    setAlert("fail");
    setOpenD(false);
  };
  const onPaymentComplete = () => {
    setAlert("success");
    setOpenD(false);
  };
  const onCancelDeposit = (deposit) => {
    //
    getPaymentToken(
      new URLSearchParams({
        userId: loggedInUser.id,
        transactionId: deposit.ticketNo,
        name: deposit.name,
        email: deposit.email,
        site: process.env.REACT_APP_SITE,
        userIp: ipAddress || "70.67.168.255",
        mobile: deposit.phoneNumber,
        currency: "CAD",
        language: "en",
        amount: deposit.amount,
        type: "ETO",
        sandbox: process.env.REACT_APP_SANDBOX,
        hosted: false,
      })
    )
      .then((payResponse) => {
        if (!payResponse.errorMsg) {
          cancelDeposit(payResponse.data, deposit.ticketNo)
            .then((response) => {
              if (response.ok && response.status == 200) {
                console.log("successfully canceled");
              } else {
                onPaymentError();
              }
            })
            .catch((error) => {
              console.error("Error:", error.message);
              onPaymentError();
            });
        } else onPaymentError();
      })
      .catch((error) => {
        console.error("Error:", error.message);
        onPaymentError();
      });
  };

  return (
    <div class=" m-auto md:ml-[2%] md:mr-[2%] ml-1 max-h md:py-12">
      {isLoading && (
        <div className="mt-[20%]">
          <SpinnerWithInfo message="Please wait..." />
        </div>
      )}
      {!isLoading && payOutData.length === 0 && (
        <div className="md:mt-[20%] p-4 m-auto mt-[60%]">
          <h1 className="m-auto md:ml-[38%] md:text-[20px] tex-[14px]">
            <b>You have no pay out yet, Click below to begin.</b>
          </h1>
          <button
            type="button"
            size="lg"
            onClick={() => setOpenD(true)}
            class="mt-6 block m-auto md:max-w-[40%] select-none rounded-lg bg-[#ab47bc] py-3 px-6 text-center align-middle  text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          >
            Start Pay out
          </button>
        </div>
      )}
      {!isLoading && payOutData.length > 0 && (
        <Card className="h-full w-full mt-1">
          <CardHeader floated={false} shadow={false} className="rounded-none">
            {alert && (
              <div className="max-w-[34rem] m-auto  md:mt-2 mt-2">
                <AlertCustomStyles
                  status={alert}
                  message={
                    alert == "fail"
                      ? "Something went wrong, Please try again!"
                      : "Pay Out successfully created"
                  }
                />
              </div>
            )}
            <div className="md:mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
              <div className="hidden md:block">
                <Typography variant="h5" color="blue-gray">
                  Pay-out
                </Typography>
                <Typography color="gray" className="mt-1 font-normal">
                  These are records of your pay-outs
                </Typography>
              </div>
              {isAdminOrTesting(loggedInUser.role) && (
                <div className="flex w-full shrink-0 gap-2 md:w-max">
                  {/* <div className="w-full md:w-72">
                    <Input
                      label="Search"
                      icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                    />
                  </div> */}
                  <Button
                    className="flex items-center gap-3"
                    size="sm"
                    onClick={() => setOpenD(true)}
                  >
                    <PlusIcon strokeWidth={2} className="h-4 w-4" /> Create pay
                    out
                  </Button>
                </div>
              )}
            </div>
          </CardHeader>
          <CardBody className=" px-0 hidden md:flex md:flex-col md:items-center ">
            <table className="w-full min-w-max table-auto text-left ">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((row, index) => {
                  const isLast = index === payOutData.length - 1;
                  const classes = isLast
                    ? "p-2 "
                    : "p-2 border-b border-blue-gray-50";

                  return (
                    <>
                      <tr key="row">
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <Avatar
                              src={"./userProfile.svg"}
                              alt="row"
                              size="md"
                              className="border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                            />

                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-[16px]"
                            >
                              {row.ticketNo}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="w-max">
                            <Chip
                              size="sm"
                              variant="ghost"
                              value={row.payOutStatus}
                              color={
                                row.payOutStatus === paymentStatuses.COMPLETED
                                  ? "green"
                                  : row.payOutStatus ===
                                    paymentStatuses.NOT_COMPLETED
                                  ? "amber"
                                  : "gray"
                              }
                            />
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className=" text-[16px] "
                            >
                              {row.name}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="text-[16px]"
                          >
                            {row.amount.toLocaleString()}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="text-[16px]"
                          >
                            {moment(row.createdAt).format("MMMM DD, YYYY")}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="text-[16px]"
                          >
                            {formatPhone(row.phoneNumber, "")}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="text-[16px]"
                          >
                            {row.email}
                          </Typography>
                        </td>
                        <td className={classes}>
                          {row.payOutStatus ==
                            paymentStatuses.NOT_COMPLETED && (
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="text-[16px]"
                            >
                              <Button
                                variant="outlined"
                                size="sm"
                                color="red"
                                className="normal-case md:text-[15px] text-[12px]"
                                onClick={() => onCancelDeposit(row)}
                                disabled={true}
                              >
                                <span>Cancel</span>
                              </Button>
                            </Typography>
                          )}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            {payOutData.length > itemsPerPage && (
              <div className="flex items-center gap-4 mt-6">
                <Button
                  variant="text"
                  className="flex items-center gap-2"
                  onClick={prev}
                  disabled={active === 1}
                >
                  <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Previous
                </Button>
                <div className="flex items-center gap-2">
                  {[...Array(pageCount)].map((elementInArray, index) => (
                    <IconButton {...getItemProps(index + 1)} color={"purple"}>
                      {index + 1}
                    </IconButton>
                  ))}
                </div>
                <Button
                  variant="text"
                  className="flex items-center gap-2"
                  onClick={next}
                  disabled={active === pageCount}
                >
                  Next
                  <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
                </Button>
              </div>
            )}
          </CardBody>
        </Card>
      )}
      <Dialog
        size="xs"
        open={openD}
        className="bg-transparent shadow-none"
        dismiss={{
          escapeKey: false,
          referencePress: false,
          outsidePress: false,
          ancestorScroll: false,
          bubbles: false,
        }}
      >
        <PayOutForm
          loggedInUser={loggedInUser}
          onComplete={() => setOpenD(false)}
          onPaymentError={onPaymentError}
          onPaymentComplete={onPaymentComplete}
          ipAddress={ipAddress}
        />
      </Dialog>
    </div>
  );
}
