import React, { useState } from "react";
import {
  Navbar,
  Typography,
  Button,
  IconButton,
  Card,
  Collapse,
  List,
  ListItem,
  ListItemPrefix,
  Menu,
  MenuHandler,
  MenuList,
  Avatar,
} from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export function StickyNavbar({ pageType }) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [openNav, setOpenNav] = React.useState(false);
  const [selectedLang, setSelectedLang] = useState("Canada");
  const changeLanguage = (country, lng) => {
    i18n.changeLanguage(lng);
    setSelectedLang(country);
  };
  const handleClose = () => {
    navigate("/");
  };
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);
  const navList = (
    <ul className="mt-4 mb-4 ml-4 flex flex-col gap-4 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <a href="#" className="flex items-center">
          Track Transfer
        </a>
      </Typography>
      <hr></hr>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <a href="#" className="flex items-center">
          Locations
        </a>
      </Typography>
      <hr></hr>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <a href="#" className="flex items-center">
          Help
        </a>
      </Typography>
      <hr></hr>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <a href="#" className="flex items-center">
          Language
        </a>
      </Typography>
      <hr></hr>
    </ul>
  );
  return (
    <div className=" -m-6  max-h-[768px] w-[calc(100%+28px)] md:mt-2 mt-1">
      <Navbar className=" h-max md:max-w-[100%] max-w-[100%] m-auto rounded-none px-4 py-0 lg:px-8 lg:py-1">
        <div className="flex items-center relative justify-between md:ml-[15%] md:max-w-[71%] text-blue-gray-900 md:pl-0 md:pr-0 pl-4 pr-2">
          <Typography
            as="a"
            href="/"
            className=" cursor-pointer py-1.5 font-semibold flex items-center gap-2"
          >
            {/* <img
              src="/logo_ams.svg"
              alt="logo"
              className="hidden md:block w-14 h-14 fill-purple-500"
            /> */}
            <div className="ml-2 text-[13px] font-extrabold md:text-[20px] text-white bg-purple-600 md:py-4 md:px-3 px-1 py-2 rounded-md md:rounded-xl">
              AMS
            </div>
            <div className=" md:block text-purple-600 flex flex-col">
              <div className=" text-[12px] md:text-[12px] ">
                Adulis Money Services
              </div>
              <div className="hidden md:block text-[10px] ml-5">
                Quick-Secure-Global
              </div>
            </div>
          </Typography>
          {pageType != "mobileView" && (
            <div className="flex items-center gap-4">
              <div className="flex items-center md:gap-x-3 gap-x-1">
                <div className="md:mr-6 mr-2  max-w-24 ">
                  <Menu>
                    <MenuHandler>
                      <div className="flex gap-1 justify-center items-center">
                        <img
                          src={`/${selectedLang}.png`}
                          className="md:w-8 md:h-8 w-5 h-5"
                          alt="Eritrea"
                        />
                        <span className="text-[13px] text-black font-extrabold">
                          {selectedLang == "Canada"
                            ? "ENG"
                            : selectedLang == "Eritrea"
                            ? "TIG"
                            : "AMH"}
                        </span>
                      </div>
                    </MenuHandler>
                    <MenuList>
                      <List className="p-1 max-w-[100px] min-w-[140px]">
                        <ListItem
                          onClick={() => changeLanguage("Eritrea", "tig")}
                          className="p-1"
                        >
                          <ListItemPrefix>
                            <img
                              src="/Eritrea.png"
                              className="md:w-8 md:h-8 w-5 h-5"
                              alt="Eritrea"
                            />
                          </ListItemPrefix>
                          <div>
                            <Typography variant="h6" color="blue-gray">
                              Tigrinya
                            </Typography>
                          </div>
                        </ListItem>
                        <ListItem
                          onClick={() => changeLanguage("Ethiopia", "amh")}
                          className="p-1"
                        >
                          <ListItemPrefix>
                            <img
                              src="/Ethiopia.png"
                              className="md:w-8 md:h-8 w-5 h-5"
                              alt="Ethiopia"
                            />
                          </ListItemPrefix>
                          <div>
                            <Typography variant="h6" color="blue-gray">
                              Amharic
                            </Typography>
                          </div>
                        </ListItem>
                        <ListItem
                          onClick={() => changeLanguage("Canada", "eng")}
                          className="p-1"
                        >
                          <ListItemPrefix>
                            <img
                              src="/Canada.png"
                              className="md:w-8 md:h-8 w-5 h-5"
                              alt="Canada"
                            />
                          </ListItemPrefix>
                          <div>
                            <Typography variant="h6" color="blue-gray">
                              English
                            </Typography>
                          </div>
                        </ListItem>
                      </List>
                    </MenuList>
                  </Menu>
                </div>
                {!pageType && (
                  <>
                    <Link to={"/login"}>
                      <Button
                        variant="outlined"
                        size="sm"
                        color="purple"
                        className="hidden lg:inline-block "
                      >
                        <span>{i18n.t("logIn")}</span>
                      </Button>
                    </Link>
                    <Link to={"/register"}>
                      <Button
                        variant="button"
                        size="sm"
                        className="hidden lg:inline-block bg-[#882699] hover:bg-[#cf3be9]"
                      >
                        <span>{i18n.t("register")}</span>
                      </Button>
                    </Link>
                  </>
                )}
                {!!pageType && pageType != "static" && (
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    class="bg-white rounded-md p-2 inline-flex items-center justify-center text-purple-600 hover:text-purple-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  >
                    <span class="sr-only">Close menu</span>
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                )}
              </div>
              <IconButton
                variant="text"
                className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                ripple={false}
                onClick={() => setOpenNav(!openNav)}
              >
                {openNav ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    className="h-6 w-6"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </IconButton>
            </div>
          )}
        </div>
        <Collapse open={openNav}>
          <hr></hr>
          {navList}
          <div className="flex flex-col h-screen gap-5 mt-10 ml-4 ">
            <Link to={"/login"}>
              <Button fullWidth variant="outlined" size="lg" className="">
                <span>Log In</span>
              </Button>
            </Link>
            <Link to={"/register"}>
              <Button
                fullWidth
                variant="button"
                className=" bg-[#882699] hover:bg-[#cf3be9]"
                size="lg"
              >
                <span>Register</span>
              </Button>
            </Link>
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
}
