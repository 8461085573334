import React, { useState, useEffect } from "react";
import {
  Card,
  Select,
  Option,
  Input,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { selectedCountries } from "../../utils/constants";
import { useCountries } from "use-react-countries";
import { isValidAmount } from "../../utils/helpers";
import { AlertCustomStyles } from "../layout/AlertCustomStyles";
import { Link, useNavigate } from "react-router-dom";
import ConversionRateService from "../../services/conversionRate.service";
import { useTranslation } from "react-i18next";
const TransferForm = () => {
  const navigate = useNavigate();
  let countries = useCountries().countries.filter((x) =>
    selectedCountries.includes(x.name)
  );
  const { t } = useTranslation();
  countries = countries.sort(function (a, b) {
    let x = a.name.toLowerCase();
    let y = b.name.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
  const [alert, setAlert] = useState("");
  const [rate, setRate] = useState("");
  const [fee, setFee] = useState("");
  const [countryPerHundredFee, setCountryPerHundredFee] = useState(0);
  const handleNextStep = () => {
    if (
      Object.values(errors).every((v) => v === 0) &&
      !Object.values(values).some((x) => x == "")
    ) {
      navigate("/login");
    } else {
      setAlert("fail");
    }
  };
  const [values, setValues] = useState({
    rCountry: countries[0].name,
    sAmount: 100,
    rAmount: "",
    rCurrency: "NKF",
    sCurrency: "CAD",
  });
  let [errors, setErrors] = useState({
    rCountry: 0,
    sAmount: 0,
  });
  const handleValidation = (e) => {
    if (e.target.name == "sAmount") {
      !isValidAmount(e.target.value)
        ? setErrors({ ...errors, [e.target.name]: 1 })
        : setErrors({ ...errors, [e.target.name]: 0 });
    }
  };
  const onChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      rAmount: parseFloat(e.target.value) * parseFloat(rate) || 0,
    });
    calculateFee(parseFloat(e.target.value), countryPerHundredFee / 100);
  };
  const handleCountry = async (e) => {
    let updateRates = await ConversionRateService.getAllConversionRates();
    let updatedDetails = updateRates;
    const selectedCountry = updatedDetails.find((x) => x.country == e);
    setValues({
      ...values,
      rCountry: e,
      rCurrency: selectedCountry.symbol,
      rAmount:
        (parseFloat(values["sAmount"]) || 0) * parseFloat(selectedCountry.rate),
      totalPay: parseFloat(values["sAmount"]) + parseFloat(selectedCountry.fee),
    });
    setRate(selectedCountry.rate);
    setCountryPerHundredFee(selectedCountry.fee);
    calculateFee(parseFloat(values["sAmount"] || 0), selectedCountry.fee / 100);
  };
  const calculateFee = (amount, feeRate) => {
    // Round the amount to the nearest multiple of 50
    const roundedAmount = Math.round(amount / 50) * 50;
    // Calculate the fee as 5% of the rounded amount
    const calculatedFee = roundedAmount * feeRate;
    setFee(calculatedFee);
  };
  useEffect(() => {
    (async () => {
      let rates = await ConversionRateService.getAllConversionRates();
      let conversionDetails = rates;
      const defaultCountry = conversionDetails.find(
        (x) => x.country == "Eritrea"
      );
      setRate(defaultCountry.rate);
      setFee(defaultCountry.fee);
      setCountryPerHundredFee(defaultCountry.fee);
      setValues({
        ...values,
        rAmount: parseFloat(100) * parseFloat(defaultCountry.rate),
      });
    })();
  }, []);
  return (
    <div className="w-full max-w-[400px] md:max-w-[24rem] mt-2 m-auto md:ml-9 rounded-none md:rounded-xl">
      <form class="max-w-screen-lg  md:mt-1 md:mb-2 w-80  ">
        {alert && (
          <AlertCustomStyles status={alert} message={"Amount is required!"} />
        )}
        <div class="flex flex-col gap-5 mb-0">
          <h6 class="block mt-3 -mb-3 text-[18px] font-nunito text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
            You are sending to
          </h6>
          <div className="w-90 md:mt-5">
            <Select
              size="lg"
              label="Send to"
              selected={(element) =>
                element &&
                React.cloneElement(element, {
                  disabled: true,
                  className:
                    "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                })
              }
              value={values["rCountry"]}
              onChange={(e) => handleCountry(e)}
            >
              {countries.map(({ name, flags, currencies }) => (
                <Option
                  key={name}
                  value={name}
                  className="flex items-center gap-2"
                >
                  <img
                    src={flags.svg}
                    alt={name}
                    className="h-5 w-5 rounded-full object-cover"
                  />
                  {name}
                </Option>
              ))}
            </Select>
          </div>
          <div class="flex flex-col  w-full gap-2 md:gap-0 ">
            <div class="relative md:h-22 h-22 w-full m-auto bg-indigo-50 md:p-3 py-1 px-3 rounded-xl ">
              <div class="flex flex-col gap-4 md:gap-0">
                <div className="relative flex flex-col gap-3">
                  <h6 class="block -mb-3 font-nunito text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                    You send
                  </h6>
                  <div className="relative flex w-full max-w-[24rem]">
                    <Menu placement="bottom-start">
                      <MenuHandler>
                        <Button
                          ripple={false}
                          variant="text"
                          color="blue-gray"
                          className="flex cursor-default md:min-h-[30px] min-h-[30px] h-10 items-center gap-2 rounded-r-none border border-r-1 border-blue-gray-200 bg-blue-gray-500/10 pl-3 disabled:text-black"
                        >
                          CAD
                        </Button>
                      </MenuHandler>
                    </Menu>
                    <div className="flex flex-col">
                      <Input
                        color="indigo"
                        name="sAmount"
                        type="number"
                        value={values["sAmount"]}
                        onBlur={handleValidation}
                        onChange={onChange}
                        error={errors["sAmount"]}
                        className={`${
                          errors["sAmount"]
                            ? "!border-t-red-600 focus:!border-t-red-600"
                            : ""
                        } rounded-l-none rounded-r-none !border-t-blue-gray-200 focus:!border-t-purple-600 text-indigo-500`}
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        containerProps={{
                          className:
                            "md:min-h-[30px] min-h-[30px] min-w-[12rem] ",
                        }}
                        style={{ fontSize: "20px", fontWeight: "700" }}
                      />
                      {errors["sAmount"] == 1 && (
                        <span class="text-[10px] md:text-[10px] ml-2 font-semibold text-[#e53935] ">
                          Send amount must be between 50.00 and 2999.00
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex md:mb-1 text-purple-600 ml-[30%] md:mt-1 items-center">
                  <div className="text-[13px]">Rate:</div>
                  <div className="flex text-[12px] text-purple-600 m-auto font-bold">
                    <span>1.00 CAD </span>
                    <ArrowRightIcon className="h-3 w-7 opacity-60 mt-[2px] " />
                    <span>
                      {rate} {values["rCurrency"]}
                    </span>
                  </div>
                </div>
                <div className="relative flex flex-col mb-3 gap-3">
                  <h6 class="block -mb-3 font-nunito text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                    Recipient gets
                  </h6>
                  <div className="relative flex w-full max-w-[21rem]">
                    <Menu placement="bottom-start">
                      <MenuHandler>
                        <Button
                          ripple={false}
                          variant="text"
                          color="blue-gray"
                          className="flex cursor-default md:min-h-[30px] min-h-[30px] h-10 items-center gap-2 rounded-r-none border border-r-1 border-blue-gray-200 bg-blue-gray-500/10 pl-3 disabled:text-black"
                        >
                          {values["rCurrency"]}
                        </Button>
                      </MenuHandler>
                    </Menu>
                    <Input
                      disabled={true}
                      value={values["rAmount"].toLocaleString()}
                      className={`border-b-2 rounded-l-none rounded-r-none !border-t-blue-gray-200 focus:!border-t-purple-600 text-indigo-500 border-b-indigo-600`}
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      containerProps={{
                        className:
                          "md:min-h-[30px] min-h-[20px] min-w-[12rem] ",
                      }}
                      style={{ fontSize: "20px", fontWeight: "700" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr className="hidden md:block mt-4" />
            <div class="relative md:px-6 px-8 md:w-[100%] ">
              <div className="flex  gap-2 md:gap-4 w-full md:mb-4 md:mt-4 mb-2 mt-2 ">
                <div className="flex flex-1 flex-col gap-1">
                  <div className=" font-bold  md:text-[16px] text-[14px]">
                    {t("fee")}:
                  </div>
                  <div className=" md:text-[15px] text-[14px]">{fee} CAD</div>
                </div>
                <div className="flex flex-1  flex-col gap-1">
                  <div className=" font-bold text-[14px] md:text-[16px]">
                    {t("totalPay")}:
                  </div>
                  {!!values["sAmount"] && (
                    <div className="  text-[14px] font-bold md:text-[17px]">
                      {parseFloat(values["sAmount"]) + parseFloat(fee)} CAD
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </form>
      <div className="md:mt-1 md:p-3 m-auto mb-5 mt-2 md:mb-2">
        <Button
          className=" bg-[#882699] hover:bg-[#cf3be9] normal-case md:text-[15px] text-[12px]"
          onClick={handleNextStep}
          size="sm"
        >
          {t("getStarted")}
        </Button>
      </div>
    </div>
  );
};
export default TransferForm;
